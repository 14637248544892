export const sentryClientConfig = {
  dsn: "https://b3ed29e852fbae8f84b1f76ab24f23ee@o288253.ingest.us.sentry.io/4506953622552576",

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  beforeSend(event: any) {
    if (process.env.NODE_ENV !== "production") {
      // Don't send dev events
      return null;
    }
    return event;
  },
};
