import { AlertSeverity, Payload, PayloadSeverity } from "./types/slack";

//TO-DO => UPDATE ICONS
const INFO_ICON_URL = `${process.env.SUBMIX_LANDING_SERVER_URL}/info.png`;
const WARNING_ICON_URL = `${process.env.SUBMIX_LANDING_SERVER_URL}/warning.png`;
const ERROR_ICON_URL = `${process.env.SUBMIX_LANDING_SERVER_URL}/error.png`;
const FATAL_ICON_URL = `${process.env.SUBMIX_LANDING_SERVER_URL}/fatal.png`;

const getSeverityIcon = (severity: PayloadSeverity) => {
  switch (severity) {
    case AlertSeverity.INFO:
      return INFO_ICON_URL;

    case AlertSeverity.WARN:
      return WARNING_ICON_URL;

    case AlertSeverity.ERROR:
      return ERROR_ICON_URL;

    case AlertSeverity.FATAL:
      return FATAL_ICON_URL;

    default:
      return "";
  }
};

export const createPayload = (payload: Payload, channel: string) => {
  const { title, message, severity, source } = payload;
  const levelIcon = getSeverityIcon(severity);

  const currentDate = new Date();
  const currentDateInMs = Math.floor(currentDate.getTime() / 1000);

  const shouldNotifyAll =
    severity === AlertSeverity.ERROR || severity === AlertSeverity.FATAL;

  return {
    channel,
    blocks: [
      {
        type: "divider",
      },
      {
        type: "context",
        elements: [
          {
            type: "image",
            image_url: levelIcon,
            alt_text: "alert severity icon",
          },
          {
            type: "mrkdwn",
            text: shouldNotifyAll ? `<!here> *${title}*` : `*${title}*`,
          },
        ],
      },
      {
        type: "divider",
      },
      {
        type: "rich_text",
        elements: [
          {
            type: "rich_text_section",
            elements: [
              {
                type: "text",
                text: "Level: ",
                style: {
                  bold: true,
                },
              },
              {
                type: "text",
                text: severity,
              },
            ],
          },
          {
            type: "rich_text_section",
            elements: [
              {
                type: "text",
                text: "Source: ",
                style: {
                  bold: true,
                },
              },
              {
                type: "text",
                text: source,
              },
            ],
          },
          {
            type: "rich_text_section",
            elements: [
              {
                type: "text",
                text: "Message: ",
                style: {
                  bold: true,
                },
              },
              {
                type: "text",
                text: message,
              },
            ],
          },
        ],
      },
      {
        type: "context",
        elements: [
          {
            type: "plain_text",
            text: `Date: `,
          },
          {
            type: "mrkdwn",
            text: `<!date^${currentDateInMs}^ {date_num} {time_secs}|${currentDate}>`,
          },
        ],
      },
      {
        type: "divider",
      },
    ],
  };
};
