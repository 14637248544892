export enum AlertSeverity {
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
  FATAL = "FATAL",
}

export type PayloadSeverity =
  | AlertSeverity.INFO
  | AlertSeverity.WARN
  | AlertSeverity.ERROR
  | AlertSeverity.FATAL;

export type Payload = {
  id: string;
  title: string;
  message: string;
  severity: PayloadSeverity;
  source: string;
};

export type SlackSendMessageSuccesResponse = {
  data: {
    channel?: string;
    deprecated_argument?: string;
    error?: string;
    errors?: string[];
    needed?: string;
    ok?: boolean;
    provided?: string;
    ts?: string;
  };
  statusCode: number;
};

export type SlackSendMessageErrorResponse = {
  data: null;
  statusCode: number;
};
