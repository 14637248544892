import { logging } from "../src/logger-manager";
import { createPayload } from "./slackNotificationPayload";
import {
  AlertSeverity,
  Payload,
  SlackSendMessageErrorResponse,
  SlackSendMessageSuccesResponse,
} from "./types/slack";

logging.registerConsoleLogger();
const logger = logging.getLogger("alert");

export const sendNotificationToSlack = async (
  payload: string,
  botToken = process.env.SLACK_BOT_TOKEN,
) => {
  try {
    const result = await fetch("https://slack.com/api/chat.postMessage", {
      method: "POST",
      body: payload,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${botToken}`,
        Accept: "application/json",
      },
    });

    if (result.status !== 200) {
      logger.error(`Failed to send alert to dev team on Slack!`);
      throw {
        statusCode: result.status,
      };
    } else {
      const responseData = await result.json();
      if (!responseData.ok) {
        logger.error(
          `Error while sending message to Slack ${JSON.stringify(
            responseData,
          )}`,
        );
        throw {
          statusCode: 400,
        };
      } else {
        return { data: responseData, statusCode: 200 };
      }
    }
  } catch (error) {
    return {
      statusCode: Number((error as { statusCode: string })?.statusCode) || 404,
      data: null,
    };
  }
};

export const alertDevTeam = async (
  payloadData: Payload,
  sendNotificationToSlack: (
    payload: string,
    botToken: string,
  ) => Promise<SlackSendMessageSuccesResponse | SlackSendMessageErrorResponse>,
  channel = process.env.SLACK_DEV_CHANNEL,
  botToken = process.env.SLACK_BOT_TOKEN,
) => {
  const payload = createPayload(payloadData, channel || "");
  const result = await sendNotificationToSlack(
    JSON.stringify(payload),
    botToken || "",
  );

  if (
    payloadData.severity === AlertSeverity.FATAL &&
    result.statusCode !== 200
  ) {
    // TO-DO => Consider adding fallback if FATAL alert happens and fails to send to Slack
  }

  if (result.statusCode !== 200 && !result.data) {
    return {
      data: null,
      statusCode: result.statusCode,
    };
  } else {
    return {
      data: result.data,
      statusCode: result.statusCode,
    };
  }
};
